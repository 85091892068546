<template>
  <div>
    <v-divider></v-divider>
      <v-tabs v-model="paymentType">
        <v-tab>Enter New Card</v-tab>
        <v-tab-item>
          <v-col cols="12" pa-2>
            <div id="sq-ccbox">
              <form id="nonce-form">
                <fieldset>
                  <div :id="id+'-sq-card-number'"></div>
                  <div class="third">
                    <div :id="id+'-sq-expiration-date'"></div>
                  </div>
                  <div class="third">
                    <div :id="id+'-sq-cvv'"></div>
                  </div>
                  <div class="third">
                    <input :id="id+'-sq-postal-code'">
                  </div>
                </fieldset>
               
                <input type="hidden" id="card-nonce" name="nonce">
              </form>
            </div>
          </v-col>
        </v-tab-item>
        <v-tab v-show="!invitee">Use Card on File</v-tab>
        <v-tab-item>
          <v-col cols="12" px-2 pb-2>
            <v-icon large red>fa-cc-visa</v-icon>
            <template v-if="savedCards">
              
              <v-radio-group hide-details v-model="selectedSavedCard">
                <v-radio
                v-for="(card, index) in savedCards"
                :key="index"
                :value="index"
              >
                  <div slot="label" class="body-2 font-weight-thin black--text">
                    {{card.card_brand + ' *' + card.last_4}}
                  </div>
                  </v-radio>
              </v-radio-group>
            </template>
            <template v-else>
                  <div class="body-2 font-weight-thin black--text">
                    No card on file. <br><br>To add one for next time, enter a new card, then click "SAVE TO FILE" <strong>before</strong> clicking "PURCHASE".
                  </div>
            </template>
          </v-col>
        </v-tab-item>
    </v-tabs>
     <template  v-if="errors">
      <div id="error">
          <p> {{ polishedErrors }} </p>
      </div>
    </template>
    <div class="text-center">
      <v-col center cols="12">
        <v-btn v-show="paymentType==0 && !invitee"
          color="blue-grey" outlined
          @click="terms = true;" 
        >
          Save Card
          <v-icon v-show="saveCardConfirmed" right dark>done</v-icon>
        </v-btn>
        <v-btn 
          :loading="isLoading" 
          :disabled="isLoading"
          color="primary" large
          @click="initiatePurchase($event)"
          >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </div>
    <v-dialog v-model="terms" width="70%">
      <v-card>
          <v-card-title class="title">Save Card On File</v-card-title>
          <v-card-text>
              Your credit card info will be encrypted and stored to your customer profile managed by Square Inc. 
              Cards on file may be used for future orders in the BOTLS app, so please ensure that your password is secure.
              <br><br>
              Your card will be saved only if you enable this option and click PURCHASE
          </v-card-text>
          <v-card-actions>
          <v-btn
              text
              color="black"
              @click="terms = false; saveCardConfirmed = false"
          >No Thanks</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="terms = false; saveCardConfirmed = true"
          >Save My Card</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import merge from 'merge'
//import 'es6-promise/auto'
import axios from 'axios'
import { mapMutations, mapGetters } from 'vuex'
import util from 'util'
export default {
  data: function() {
    return {
      errors: null,
      masterpass: false,
      loading: false,
      applePay: false,
//      loader: null,
      built: false,
      allowSaveCard: true,
      terms: false,
      saveCardConfirmed: false,
      paymentType: 0, //new card
      postalCode: "",
      selectedSavedCard: 0,
    };
  },
  watch: {
    showPaymentForm: function() {
        if (!this.showPaymentForm) {
            return 1;
        }
        else{
        if (SqPaymentForm.isSupportedBrowser() && !this.built) {
            this.loading = true
            this.paymentForm.build();
        //    this.paymentForm.recalculateSize();
            this.built = true
            this.loading = false
        }
        }
    },
  },
  props: {
    showPaymentForm: Boolean,
    buttonText: String,
    id: Number,
  },
  mounted: function() {
    this.injectCheckoutScript()
        .then(() => this.configureSquare())
        .catch(e => console.error(e));
  },
  computed: {
    invitee () {return this.$store.state.userInfo.userStatus == "invitee"},
    savedCards () {
      return this.userInfo().cards
    },
    isLoading () {  //computed property merges local loading and state.loading
      return this.loading || this.$store.state.loading
    },
    polishedErrors() {
      if(this.errors == null)
        return null
      let message = ""
      this.errors.forEach( (error) => {
        message += error + " "
      })
      return message
    }
  },
  methods: {
    // ...mapMutations([
    //   'recordPayment',
    // ]),
    ...mapGetters([
      'cart',
    //  'cart2',
      'userInfo',
    ]),

    getCardOnFileId () {
      if( this.choosePayment == "new")
        return null
      else{
        let cardIndex = this.selectedSavedCard
        return this.userInfo().cards[cardIndex].id
      }
    },
    initiatePurchase (event) {
      this.errors = null //clear errors in case this is not the first attempt
      this.loading = true
      if(this.paymentType == 0){
        this.requestCardNonce(event)
      }
      else{
        this.submitPayment(null, null, this.getCardOnFileId())
      }
    },
    requestCardNonce (event) {
      // Don't submit the form until SqPaymentForm returns with a nonce
      event.preventDefault()

      // Request a nonce from the SqPaymentForm object
      this.paymentForm.requestCardNonce()
    },
    injectCheckoutScript() {
        let self = this
        let el = document.createElement('SCRIPT')
        let ctr = 0
        let scriptSource = 'https://js.squareup.com/v2/paymentform.js'
        let scripts = document.getElementsByTagName('script');
        let scriptExists = false
        for (var i in scripts){
            if (scripts[i].src == scriptSource) {
                scriptExists = true
            }
        }
        el.setAttribute('src', scriptSource);
        if(!scriptExists) {
            document.querySelector("#" + "sq-ccbox").appendChild(el)
        }
        return new Promise((resolve, reject) => {
            let handle = window.setInterval(function () {
                if (window.SqPaymentForm) {
                    self.loaded = true
                    resolve()
                    clearInterval(handle)
                }
                ctr++
                if (ctr > 1000) {
                    reject("Unable to load paymentform.js")
                    clearInterval(handle)
                }
            }, 5)
        })
    },
    configureSquare () {
        let locationId = ENV.squareLocationId(this.$store.getters.isAdmin); 
        let applicationId = ENV.squareApplicationId(this.$store.getters.isAdmin); //FIREBASE.auth.currentUser.uid
        let that = this;
        this.paymentForm = new SqPaymentForm({
        autoBuild: false,
        applicationId: applicationId,
        locationId: locationId,
        inputClass: "sq-input",
        // Initialize the payment form elements
        // Customize the CSS for SqPaymentForm iframe elementsfalse
        inputStyles: [{
        //    fontSize: '14px',
        //    fontFamily: 'Helvetica Neue',
            padding: '12px',
         //   color: '#ffb3b3',//'#373F4A',
            backgroundColor: 'transparent',
            lineHeight: '20px',
        //    placeholderColor: '#CCC',
            _webkitFontSmoothing: 'antialiased',
            _mozOsxFontSmoothing: 'grayscale'
        }],
        applePay: false,
        masterpass: false,
        cardNumber: {
            elementId: that.id + "-sq-card-number",
            placeholder: "Card number"
        },
        cvv: {
            elementId: that.id + "-sq-cvv",
            placeholder: "CVV"
        },
        expirationDate: {
            elementId: that.id + "-sq-expiration-date",
            placeholder: "MM / YY"
        },
        postalCode: {
            elementId: that.id + "-sq-postal-code",
            placeholder: "Zip Code"
        },
        // SqPaymentForm callback functions
        callbacks: {
            methodsSupported: function(methods) {
                that.applePay = methods.applePay;
                that.masterpass = methods.masterpass;
            },
            /*
            * Digital Wallet related functions
            */
            createPaymentRequest: function() {
                var paymentRequestJson;
                /* ADD CODE TO SET/CREATE paymentRequestJson */
                return paymentRequestJson;
            },
            validateShippingContact: function(contact) {
                var validationErrorObj;
                
                /* ADD CODE TO SET validationErrorObj IF ERRORS ARE FOUND */
                return validationErrorObj;
            },
            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card nonce request
            */
            cardNonceResponseReceived: function(errors, nonce, cardData) {
                let self = that
                if (errors) { //display errors and do not submit payment
                    errors.forEach(function(error) {
                      if(self.errors == null)
                        self.errors = []
                      self.errors.push(error.message);
                      self.loading = false
                    });
                    return;
                }

                // Assign the nonce value to the hidden form field
                document.getElementById("card-nonce").value = nonce;
                self.submitPayment(nonce, cardData, null)
            },
            /*
            * callback function: paymentFormLoaded
            * Triggered when: SqPaymentForm is fully loaded
            */
            paymentFormLoaded: function() {
                console.log("paymentFormLoaded");
                this.loading = false
                /* HANDLE AS DESIRED */
            }
        }
        });
    },
    submitPayment (nonce, cardData, savedCardId) { //charges entered cc for whatever is in the cart
      let self = this
      const functionServer = ENV.functionsServer()
      return new Promise( function(resolve) {
        axios.post(functionServer + "purchasecart", 
        { nonce: nonce, 
          cardId: savedCardId,
        //  order: self.cart(), 
          cart: self.cart(), 
          customer: self.userInfo(),
          saveCard: self.saveCardConfirmed,
          postal_code: cardData == null ? null: cardData.billing_postal_code})
          .then( function(json) {
            self.loading = false
            self.$emit('payment-successful')
            if(self.saveCardConfirmed){
              this.loadUserInfo() //get the new card added
            }
            resolve(json);
          })
          .catch( function(err) {
            console.log(util.inspect(err,true,5,true))
            if(self.errors == null)
              self.errors = []
              if(err.response){
                err.response.data.messages.forEach( (message) => {
                  self.errors.push(message)
                })
              }
            self.loading = false
          //  reject("Payment error")
          });
      });     
    }
  }
};
</script>

<style>

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

.third {
  float:left;
  width: calc((100% - 24px) / 3);
  padding: 0;
  margin: 0 12px 12px 0;
}

.third:last-of-type {
  margin-right: 0;
}

/* Define how SqPaymentForm iframes should look */
.sq-input {
  box-sizing: border-box;
  border: 1px solid #E0E2E3;
  border-radius: 4px;
  outline-offset: -2px;
  display: inline-block;
  -webkit-transition: border-color .2s ease-in-out, background .2s ease-in-out;
     -moz-transition: border-color .2s ease-in-out, background .2s ease-in-out;
      -ms-transition: border-color .2s ease-in-out, background .2s ease-in-out;
          transition: border-color .2s ease-in-out, background .2s ease-in-out;
}

/* Define how SqPaymentForm iframes should look when they have focus */
.sq-input--focus {
  border: 1px solid #4A90E2;
  background-color: rgba(74,144,226,0.02);
}


/* Define how SqPaymentForm iframes should look when they contain invalid values */
.sq-input--error {
  border: 1px solid #E02F2F;
  background-color: rgba(244,47,47,0.02);
}

#sq-card-number {
  margin-bottom: 8px;
}

/* Customize the "Pay with Credit Card" button */
.button-credit-card {
  width: 100%;
  height: 56px;
  margin-top: 10px;
  background: #4A90E2;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  -webkit-transition: background .2s ease-in-out;
     -moz-transition: background .2s ease-in-out;
      -ms-transition: background .2s ease-in-out;
          transition: background .2s ease-in-out;
}

.button-credit-card:hover {
  background-color: #4281CB;
}


#error {
  width: 100%;
  margin-top: 16px;
  font-size: 14px;
  color: red;
  font-weight: 500;
  text-align: center;
  opacity: 0.8;
}
</style>

<style scoped>
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: greyscale;
}

body, html {
  color: #373F4A;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}

iframe {
  margin: 0;
  padding: 0;
  border: 0;
}

button {
  border: 0;
}

hr {
  height: 1px;
  border: 0;
  background-color: #CCC;
}

</style>